import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import { setAlert } from './globalAppSlice';

// Fetch Zoho Accounts API
export const fetchZohoAccounts = async (searchValue) => {
  try {
    const response = await axiosInstance.post(`/api/searchZohoAccounts`, {
      searchValue: searchValue,
    });

    if (response?.data?.data && response?.data?.success) {
      return response?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

// Fetch Zoho Leads API
export const fetchZohoLeads = async (searchValue) => {
  try {
    const response = await axiosInstance.post(`/api/searchZohoLeads`, {
      searchValue: searchValue,
    });

    if (response?.data?.data && response?.data?.success) {
      return response?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

// Search Zoho Leads (Async Thunk)
export const searchZohoLeads = createAsyncThunk(
  '/api/searchZohoLeads',
  async (searchValue, { dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/searchZohoLeads`, {
        searchValue: searchValue,
      });

      if (response?.data?.data && response?.data?.success) {
        return response?.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

// Remove Zoho Account From Layer
export const removeZohoAccountFromLayer = createAsyncThunk(
  'zoho/removeZohoAccount',
  async (accountId, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.delete(`/api/removeZohoAccount/${accountId}`);
      
      if (response.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return accountId;
      } else {
        return rejectWithValue(response.data?.message || 'Failed to remove account from layer');
      }
    } catch (error) {
      console.error('Error in API call:', error);
      return rejectWithValue(error.response?.data || 'Error removing Zoho account from layer');
    }
  }
);

// Search Zoho Accounts (Async Thunk)
export const searchZohoAccounts = createAsyncThunk(
  '/api/searchZohoAccounts',
  async (searchValue, { dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/searchZohoAccounts`, {
        searchValue: searchValue,
      });

      if (response?.data?.data && response?.data?.success) {
        return response?.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

// Zoho Slice
export const zohoSlice = createSlice({
  name: 'zoho',
  initialState: {
    zohoAccounts: [],
    zohoLeads: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Action to clear Zoho data
    clearZohoData(state) {
      state.zohoAccounts = [];
      state.zohoLeads = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchZohoAccounts.fulfilled, (state, action) => {
        if (action.payload?.data) {
          let zohoAccounts = [];
          const zohoAccountsData = action.payload?.data;
          zohoAccountsData?.forEach((zohoAccount) => {
            const accountData = {
              id: zohoAccount?.id,
              label: zohoAccount?.Account_Name,
              email: zohoAccount?.Email,
              Location_Map_Layer: zohoAccount?.Location_Map_Layer,
            };
            zohoAccounts.push(accountData);
          });
          state.zohoAccounts = zohoAccounts;
        }
        state.loading = false;
      })
      .addCase(searchZohoAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchZohoAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || 'Failed to search Zoho accounts';
      })
      .addCase(searchZohoLeads.fulfilled, (state, action) => {
        if (action.payload?.data) {
          let zohoLeads = [];
          const zohoLeadsData = action.payload?.data;
          zohoLeadsData?.forEach((zohoLead) => {
            const leadData = {
              id: zohoLead?.id,
              label: zohoLead?.Full_Name,
              email: zohoLead?.Email,
              Location_Map_Layer: zohoLead?.Location_Map_Layer,
            };
            zohoLeads.push(leadData);
          });
          state.zohoLeads = zohoLeads;
        }
        state.loading = false;
      })
      .addCase(searchZohoLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchZohoLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || 'Failed to search Zoho leads';
      })
      .addCase(removeZohoAccountFromLayer.fulfilled, (state, action) => {
        const accountId = action.payload;
        state.zohoAccounts = state.zohoAccounts.map((account) =>
          account.id === accountId ? { ...account, Location_Map_Layer: null } : account
        );
        state.loading = false;
      })
      .addCase(removeZohoAccountFromLayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeZohoAccountFromLayer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export clearZohoData action
export const { clearZohoData } = zohoSlice.actions;

// Export the reducer
export default zohoSlice.reducer;
